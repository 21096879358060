<template>
    <div>
        <tab name="2"></tab>
        <div class="page-content-box">
            <div class="page-filter-box">
                <el-radio-group v-model="params.type" size="small" @change="search">
                    <el-radio-button :label="1">来自秀动上传的</el-radio-button>
                    <el-radio-button :label="2">来自秀动发行的</el-radio-button>
                </el-radio-group>
                <div v-if="params.type == 1" class="MT20 color-danger">以下歌曲是直接上传至秀动供用户试听的，您可以任意编辑管理</div>
                <div v-else class="MT20 color-danger">以下歌曲是通过秀动发行发布给秀动供用户试听的，您可以控制歌曲是否在秀动展示，更多歌曲管理，点击前往<a href="javascript:void(0)" @click="$release()" class="jump"><i class="iconfont">&#xe63a;</i>秀动发行</a>操作</div>
            </div>
            <div class="page-list-box">
                <el-table
                    :data="dataList"
                    style="width: 100%"
                    v-loading="listLoading"
                    size="small"
                    header-row-class-name="page-list-table"
                >

                    <el-table-column prop="id" label="歌曲ID"></el-table-column>
                    <el-table-column prop="name" label="歌曲名称" min-width="120" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="time" label="时长"></el-table-column>
                    <template v-if="params.type == 1">
                        <el-table-column prop="tagVOS" label="风格" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(item,index) in scope.row.tagVOS" :key="index">
                                    {{item.styleName}}{{item.tagName ? '-'+item.tagName : item.tagName}}
                                    {{scope.row.tagVOS.length > index+1 ? '/' : ''}}
                                </span>   
                            </template>
                        </el-table-column>
                        <el-table-column prop="clickNum" label="播放次数"></el-table-column>
                        <el-table-column prop="createTime" label="上传时间" min-width="150"></el-table-column>
                    </template>
                    <el-table-column prop="display" label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.display == 1" class="color-success">显示</span>
                            <span v-else class="color-info" >隐藏</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="120">
                        <template slot-scope="scope">
                            <template v-if="params.type == 1">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="edit(scope.row)"
                                    >编辑</el-button
                                >
                                <el-button
                                    v-if="scope.row.playUrl"
                                    type="text"
                                    size="small"
                                    @click="$player.play(scope.row)"
                                    >播放</el-button
                                >
                            </template>
                            <el-button
                                v-else
                                type="text"
                                size="small"
                                @click="changeStatus(scope.row, 2)"
                                >{{ scope.row.display == 1 ? '隐藏' : '显示'}}</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div  class="page-pagination-box clearfix MT20">
                <div class="fl">
                    <el-button type="primary" size="small"  @click="$refs.musicEdit.show()" class="fl">直接上传歌曲到秀动供用户试听</el-button><br>
                    <span class="fl MT10"> 全球上架，100%收益，前往<a href="javascript:void(0)" @click="$release()" class="jump"><i class="iconfont">&#xe63a;</i>秀动发行</a>上传歌曲</span>
                </div>
                <el-pagination
                    v-if="data"
                    class="fr"
                    @size-change="handleSizeChange"
                    @current-change="getDataList"
                    :current-page.sync="params.pageNo"
                    :page-sizes="[15, 30, 50]"
                    :page-size.sync="params.pageSize"
                    layout="sizes, prev, pager, next"
                    :total="data.totalCount || 0">
                </el-pagination>
            </div>
        </div>
        <music-edit ref="musicEdit" @onUpdate="getDataList()"></music-edit>
    </div>
</template>

<script>
import Tab from './components/tab'
import MusicEdit from './components/music-edit';

export default {
    components:{Tab,MusicEdit},
    data(){
        return{
            data: null,
            dataList: null,
            params:{
                pageNo: 1,
                pageSize: 15,
                type: 1 //歌曲来源类型 1:秀动 2:发行
            },
            listLoading: true,
        }
    },
    mounted(){
        this.getDataList();
    },
    methods:{
        //获取列表
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/userCommon/musicList",
                method: "POST",
                data: this.params,
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result;
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        search(){
            this.data = null;
            this.dataList = [];
            this.params.pageNo = 1;
            this.getDataList();
        },
        //编辑事件
        edit(data){
            this.$refs.musicEdit.show(data);
        },
        //切换状态
        changeStatus(data, type = 2){
            this.$request({
                url: '/userCommon/updateMusic',
                data: {
                    ...data,
                    display: data.display == 1 ? 0 : 1,
                    type: type
                },
                method: "post",
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state == 1) {
                        this.getDataList();
                    } else {
                        this.httpError = true;
                        this.$message.error(msg || "操作失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("操作失败,请重试");
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.jump{
    text-decoration: underline;
    color: #5914ee;
    font-weight: 700;
    i{
        font-size: 12px;
    }
}
</style>