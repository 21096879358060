<template>
    <el-drawer
        :title="formData && formData.id ? '编辑歌曲' : '上传歌曲'"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        :show-close="false"
    >
        <div class="drawer-content">
            <el-form
                :model="formData"
                :rules="rules"
                ref="formData"
                label-position="top"
            >
                <el-form-item v-if="!formData.id" prop="playUrl">
                    <span slot="label">文件: <small class="color-info">只能上传MP3</small></span>
                    <el-upload 
                        drag 
                        action
                        :http-request="uploadMusic"
                        :auto-upload="false"
                        ref="upload"
                        accept=".mp3"
                        :show-file-list="true"
                        :before-upload="beforeUpload"
                        :file-list="fileList"
                        :on-change="onChange"
                        :on-remove="onRemove"
                        :disabled="uploadLoading"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击选择文件</em></div>
                    </el-upload>
                </el-form-item>
                <el-form-item prop="display" label="状态:">
                    <el-radio-group v-model="formData.display" size="medium">
                        <el-radio :label="1">显示</el-radio>
                        <el-radio :label="0">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item prop="name" label="名称:">
                    <el-input v-model="formData.name" placeholder="请输入歌曲名称" size="medium"></el-input>
                </el-form-item>
                <el-form-item prop="styles" label="风格:">
                    <el-cascader
                        v-if="drawerShow"
                        v-model="styles"
                        :options="styleList"
                        filterable
                        :props="{multiple:true,label:'name',children:'tags',value:'id',emitPath:true,checkStrictly:true}"
                        size="medium"
                        style="width:100%"
                        @change="selectStyles">
                        <template slot-scope="{ node, data }">
                            <span>{{ data.name }}</span>
                            <span v-if="!node.isLeaf"> ({{ data.tags.length }}) </span>
                        </template>
                    </el-cascader>
                </el-form-item>
            </el-form>
        </div>
        <div class="drawer-footer">
            <el-button v-if="!btnLoading" @click="close">取消</el-button>
            <el-button type="primary" @click="submitForm()" :loading="btnLoading"
                >提交</el-button
            >
        </div>
    </el-drawer>
</template>

<script>
import {fetchAllTag} from '@/assets/js/commonApi'
import {uploadAudio} from '@/assets/js/commonApi.js';

const defaultFormData = {
    display:1,
    name:"",
    playUrl:"",
    styles:[],
    tags:[],
    time:'',
    type: 1
}
export default {
    
    data(){
        return{
            drawerShow: false,
            btnLoading:false,
            formData:{...defaultFormData},
            rules:{
                name: [{ required: true, message: "请输入歌曲名称" }],
                playUrl: [{ required: true, message: "请上传歌曲" }],
            },
            styles:[],
            styleList:[],//所有风格Tag集合
            httpError:false,
            fileList:[],
            uploadLoading:false,
        }
    },
    created(){
        this.getStyleList();
    },
    methods:{
        //获取所有风格
        async getStyleList(){
            const res = await fetchAllTag({}).catch(() => {});
            const{ state, result} = res.data;
            if( state == 1 ){
                this.styleList = result;
            }
        },
        close() {
            // this.formData = {...defaultFormData};
            this.styles = [];
            this.$refs['formData'].clearValidate();
            this.$refs['formData'].resetFields();
            this.drawerShow = false;
            this.fileList = [];
        },
        show(data) {
            this.formData = {
                ...defaultFormData,
                ...data,
               styles: [],
               tags:[]
            }

            if(data && data.tagVOS){
                //风格id
                // this.formData.styles = data.styles ? data.styles.split(',') : [];
                // this.formData.tags = data.tags ? data.tags.split(',') : [];

                data.tagVOS.map(item => {
                    const _arry = [];
                    _arry.push(item.styleId);

                    this.formData.styles.push(item.styleId);
                    if(item.tagId){
                        this.formData.tags.push(item.tagId);
                        _arry.push(item.tagId);
                    }
                    
                    this.styles.push(_arry);
                })
            }

            this.drawerShow = true;
        },
        onChange(file){
            if(file){
                let _name = file.name;
                _name = _name.substring(0,_name.lastIndexOf('.'))
                this.formData.name = _name;
                this.formData.playUrl = file.name ;
                this.$refs["formData"].clearValidate('playUrl');
                this.fileList = [{...file}]
            }else{
                this.formData.playUrl = '';
            }
            this.httpError = false;
        },
        onRemove(){
            this.formData.playUrl = '';
            this.httpError = false;
        },
        beforeUpload(file){
            const isType = file.type === 'audio/mpeg';
            if (!isType) {
                this.$message.error('只能上传MP3/WAV文件!');
            }
            return isType;
        },
        //选取风格
        selectStyles(){
            // 风格&tag id组装
            let _styles = [], _tags = [];
            this.styles.forEach( item =>  {
                _styles.push( item[0] );
                if(item[1]){
                    _tags.push( item[1] ); 
                }
            })

            this.formData.styles = _styles ? [...new Set(_styles)] : [];
            this.formData.tags = _tags ? [...new Set(_tags)] : [];
        },
        async uploadMusic(req){
            this.btnLoading = true;
            this.uploadLoading = true;
            const formData = new FormData();
            formData.append('file', req.file);

            // let filePath;
            const res = await uploadAudio(formData).catch(err => {
                this.$message({message: err.message, type: 'error'});
                this.httpError = false;
                this.fileList = [];
                this.formData.playUrl = '';
            }).finally(() => {
                this.uploadLoading = false;
            });
            const{state,msg,result} = res.data;
            if (state == 1 && result) {
                this.formData.playUrl = result.urlFull;
                this.formData.time = result.time;
                this.submitAction();
            }else{
                this.$message.error(msg || '上传失败');
                this.btnLoading = false;
                this.httpError = false;
                this.fileList = [];
                this.formData.playUrl = '';
            }
        },
        // 表单提交
        submitForm() {
            let self = this;
            self.$refs["formData"].validate((valid) => {
                if (valid) {
                    if( this.formData.id || this.httpError){
                        this.submitAction()
                    }else{
                        this.$refs.upload.submit();
                    }
                } else {
                    return false;
                }
            });
        },
        submitAction(){
            const _url = this.formData.id ?  "/userCommon/updateMusic" : "/userCommon/addMusic";
            this.btnLoading = true;
            this.$request({
                url: _url,
                data: this.formData,
                method: "post",
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state == 1) {
                        this.$emit('onUpdate');
                        this.close();
                    } else {
                        this.httpError = true;
                        this.$message.error(msg || "操作失败,请重试");
                    }
                })
                .catch(() => {
                    this.httpError = true;
                    this.$message.error("操作失败,请重试");
                })
                .finally(() => {
                    this.btnLoading = false;
                });   
        }
    }
}
</script>


<style lang="scss" scoped>
::v-deep .el-upload , 
::v-deep .el-upload-dragger{
    width: 100%;
}
</style>